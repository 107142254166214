'use client';

import FeatureBanner from './FeatureBanner';
import {earthFill} from 'src/utils/icons';

const videoSource = {
  light: '/videos/visitedCountriesLight_small.mp4',
  dark: '/videos/visitedCountriesDark_small.mp4'
};

const previewImageSrc = {
  light: '/videos/visitedCountriesPreviewLight_thumb.jpg',
  dark: '/videos/visitedCountriesPreviewDark_thumb.jpg'
};

const VisitedCountriesBanner = () => {
  return (
    <FeatureBanner
      title="A map of your visited countries"
      description="Share where you have been with your own interactive map of the world."
      buttonLabel="Create my Map"
      videoSource={videoSource}
      icon={earthFill}
      href="/visited"
      buttonVariant="translucent"
      blobClassName="bg-[#C2F5C0]"
      previewImageSrc={previewImageSrc}
    />
  );
};

export default VisitedCountriesBanner;
