'use client';

import FeatureBanner from './FeatureBanner';
import {bucket} from 'src/utils/icons';

const videoSource = {
  light: '/videos/bucketListLight_small.mp4',
  dark: '/videos/bucketListDark_small.mp4'
};

const previewImageSrc = {
  light: '/videos/bucketListPreviewLight_thumb.jpg',
  dark: '/videos/bucketListPreviewDark_thumb.jpg'
};

const BucketListBanner = () => {
  return (
    <FeatureBanner
      title="Your travel bucket list"
      description="Keep track of where you want to go with an interactive travel bucket list."
      buttonLabel="Create my Bucket List"
      videoSource={videoSource}
      icon={bucket}
      href="/travel-bucket-list"
      buttonVariant="primary"
      blobClassName="bg-[#652783] right-auto -left-32 -bottom-20 w-[100%]"
      previewImageSrc={previewImageSrc}
    />
  );
};

export default BucketListBanner;
