'use client';

import {useRouter} from 'next/navigation';
import type {AnchorHTMLAttributes} from 'react';
import {Link, type LinkProps} from 'react-aria-components';

type Props = AnchorHTMLAttributes<HTMLAnchorElement> &
  LinkProps & {
    prefetch?: boolean;
    scroll?: boolean;
    ref?: any;
  };

const FastLink = (props: Props) => {
  const router = useRouter();

  if (props.prefetch && props.href && typeof window !== 'undefined') router.prefetch(props.href);

  return (
    <Link
      {...props}
      routerOptions={{scroll: props.scroll === undefined ? true : props.scroll}}
      onHoverStart={(e) => {
        if (props.href) router.prefetch(props.href);
        if (props.onHoverStart) props.onHoverStart(e);
      }}
      onPressStart={(e) => {
        if (props.href) router.prefetch(props.href);
        if (props.onPressStart) props.onPressStart(e);
      }}
      onFocus={(e) => {
        if (props.href) router.prefetch(props.href);
        if (props.onFocus) props.onFocus(e);
      }}
    />
  );
};

export default FastLink;
