'use client';

import {useRef} from 'react';
import {twMerge} from 'tailwind-merge';
import {m as motion} from 'motion/react';
import useDarkMode from 'src/hooks/useDarkMode';
import useMounted from 'src/hooks/useMounted';
import FadingImage from '../FadingImage';
import BaseButton from '../BaseButton';

type Props = {
  title: string;
  description: string;
  buttonLabel: string;
  icon: React.ReactNode;
  href: string;
  videoSource: {
    light: string;
    dark: string;
  };
  buttonVariant: 'primary' | 'translucent';
  blobClassName?: string;
  previewImageSrc: {
    light: string;
    dark: string;
  };
};

const FeatureBanner = ({
  title,
  description,
  buttonLabel,
  icon,
  videoSource,
  href,
  buttonVariant,
  blobClassName,
  previewImageSrc
}: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const isMounted = useMounted();
  const {isDark} = useDarkMode();

  return (
    <div
      className="@container group grow bg-(--subtle-color) rounded-2xl relative flex flex-col items-center overflow-hidden"
      onMouseOver={() => videoRef.current?.play()}
      onFocus={() => videoRef.current?.play()}
      onMouseLeave={() => videoRef.current?.pause()}
      onBlur={() => videoRef.current?.pause()}
    >
      <div
        className={twMerge(
          'absolute -right-32 -bottom-14 w-[80%] h-[60%] rounded-[100%] blur-[90px] dark:opacity-30',
          blobClassName
        )}
      />

      <div className="text-center flex flex-col items-center gap-2 my-16 @max-[520px]:my-12 @max-[400px]:my-10 px-4 relative">
        <h3 className="text-3xl max-sm:text-2xl font-heading font-semibold">{title}</h3>
        <p className="text-(--dark-text) max-w-[400px] text-balance">{description}</p>

        <div className="mt-4 bg-[rgba(0,0,0,0.04)] dark:bg-[rgba(255,255,255,0.05)] rounded-full p-2">
          <BaseButton href={href} buttonVariant={buttonVariant} label={buttonLabel} icon={icon} iconSize={20} />
        </div>
      </div>

      <motion.div className="w-[90%] aspect-[1.73] relative -mb-[20%] perspective-midrange">
        <motion.div className="absolute w-full h-full flex items-center justify-center rounded-2xl max-[1280px]:rounded-xl max-[460px]:rounded-lg overflow-hidden bg-(--page-background) shadow-[0_0_0_1px_rgba(0,0,0,0.08),0_214px_85px_rgba(0,0,0,0.01),0_120px_72px_rgba(0,0,0,0.03),0_53px_53px_rgba(0,0,0,0.04),0_13px_29px_rgba(0,0,0,0.05)] dark:shadow-[0_0_0_1px_rgba(255,255,255,0.06)] transform-3d group-hover:rotate-x-12 group-hover:-translate-y-10 max-[460px]:group-hover:-translate-y-4 transition-transform duration-500">
          {isMounted ? (
            <>
              <FadingImage src={previewImageSrc[isDark ? 'dark' : 'light']} alt={title} fill />

              <video
                key={isDark ? 'dark' : 'light'}
                ref={videoRef}
                className="w-[100.2%] h-[100.2%] mt-[-0.1%] relative"
                loop
                muted
                playsInline
              >
                <source src={videoSource[isDark ? 'dark' : 'light']} type="video/mp4" />
              </video>
            </>
          ) : null}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default FeatureBanner;
