type PlausibleEventOptions = {
  callback?: () => void;
  props?: Record<string, string | number | boolean | undefined>;
};

const recordEvent = (eventName: string, options?: PlausibleEventOptions) => {
  if (typeof window !== 'undefined' && window.plausible) window.plausible(eventName, options);
};

export default recordEvent;
